<template>
    <div class="main">
        <v-container class="wrapper">
        <v-layout row wrap>
        <v-container>

            <!-- <v-layout justify-center align-center class="login__top-logo-layout">
                <img class="login__top-logo" v-bind:src="'/static/img/fast_logo.png'"/>
            </v-layout> -->

            <v-layout justify-center align-center class="login__top-logo-layout">
                <div class="logging_writing">{{ lview.title }}</div>
                <div class="logging_username" style="padding-top: 30px;font-weight: bold;font-size:18px">{{ username }}</div>
            </v-layout>
            
            
            <!--<div class="personal_data"> Imię i nazwisko </div>-->

            <v-progress-circular indeterminate color="primary" v-if="isActiveSpinner">
            </v-progress-circular>

            <!-- <v-form-checkbox> -->
                <!-- Oświadczam iż zapoznałem się i zgadzam się z obowiązującą Polityką Prywatności -->
                <!-- <v-flex>Oświadczam iż zapoznałem się i zgadzam się z Warunkami Świadczenia Usługi</v-flex> -->
            <!-- </v-form-checkbox> -->
            <!--<v-form id="agreements">-->
            <div v-if="state == 'default'">
                 <v-layout align-center style="width: 100%; height: 50px; background-color: lightgray;">
                    <strong> {{ lview.label1 }} </strong>
                </v-layout>
                <div style="font-size: 15px;"> {{ lview.descriptionLabel1 }} </div>
                <!--<v-dialog
                    ref="dialog"
                    v-model="showDatePicker"
                    :return-value.sync="birthDate"
                    :rules="[validationRules.birthDate]"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="birthDate"
                            :label=lview.birthDate
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error="errors.birthDate.trials"
                            :error-messages="errors.birthDate.label"
                        ></v-text-field>
                    </template>

                    <v-date-picker v-model="date" scrollable :max="new Date().toISOString().substr(0, 10)">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="showDatePicker = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(date); errors.birthDate.label = '';"> OK </v-btn>
                    </v-date-picker>
                </v-dialog>-->

                <v-text-field prepend-inner-icon="mdi-lock"
                                    v-model="password"
                                    :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'" :type="passwordShow ? 'text' : 'password'" 
                                    :label="lview.password"
                                    name="password"
                                    autocomplete="current-password"
                                    :rules="[validationRules.password]"
                                    :error-messages="errors.password.label"
                                    color="rgba(4, 202, 90, 1)"
                                    @input="errors.password.label=''; validateRepeatPassword()"
                                    @click:append="passwordShow = !passwordShow"></v-text-field>

                <v-text-field prepend-inner-icon="mdi-lock"
                                    v-model="repeatPassword"
                                    :append-icon="confirmPasswordShow ? 'mdi-eye' : 'mdi-eye-off'" :type="confirmPasswordShow ? 'text' : 'password'" 
                                    :label="lview.repeatPassword"
                                    name="repeat-password"
                                    @input="errors.confirmPassword.label = ''; validateRepeatPassword();"
                                    :error-messages="errors.confirmPassword.label"
                                    color="rgba(4, 202, 90, 1)"
                                    @click:append="confirmPasswordShow = !confirmPasswordShow"></v-text-field> 

                <v-layout align-center style="margin-top: 10px; width: 100%; height: 50px; background-color: lightgray;">
                    <strong> {{ lview.label2 }} </strong>
                </v-layout>
                <v-checkbox
                    color="primary"
                    v-model="politicsAgree"
                    :label="lview.politicsAgreement1"
                    @click="politicsError = false"
                    :error="politicsError"
                ></v-checkbox>
                <v-checkbox
                    color="primary"
                    v-model="serviceAgree"
                    :label="lview.politicsAgreement2" 
                    style="border-bottom: solid 2px black"
                    @click="serviceError = false"
                    :error="serviceError"
                ></v-checkbox>
                <div v-if="!serviceAgree || !politicsAgree" style="color: red"> 
                        {{ lview.markAgreementsToContinue }}
                </div>

                <v-layout justify-center mt-3>
                    <v-btn :loading="loading"  large color="primary" type="submit" @click="handleSubmit" style="width:250px; color: white;">{{ lbuttons.continue }}</v-btn>
                </v-layout>
                <!--</v-form>-->

                <v-layout justify-center>
                    <v-btn text small @click="handleCancel" color="grey" style="width:250px; margin-top: 10px;">{{ lbuttons.cancel }}</v-btn>
                </v-layout>

                <!-- <v-layout justify-center mt-3>
                    <img v-bind:src="logo" />
                </v-layout>
                <div style="width: 150px">
                    <div style="color:green">
                        <span style="font-size:10px"> *Dev API URL</span>
                    </div>
                    <div>
                        <input placeholder="" type="text" v-model="current_env" size="35" style="color:gray; font-size:10px">
                    </div>
                </div>
                 -->
            </div>

            <v-layout v-if="state == 'outdated'" class="content-middler" column justify-center align-center>
                <v-icon size="100" style="color: red; border: 2px solid red; border-radius: 50%;"> mdi-exclamation </v-icon>
                <div style="width:90%; text-align: center; margin-top:10px; font-size: 20px; font-weight: 650;">{{ lview.activationLinkOutdated }}</div>

                <div class="my-2" v-if="outdatedState == 'default'">
                    <v-btn style="width: 300px" color="primary" dark large @click="resendActivationLink">
                        {{ lview.resendActivationLink }}
                    </v-btn>
                </div>

                <div class="my-2" style="width: 300px" v-else-if="outdatedState == 'sending'">
                    <v-progress-linear
                        style="width:100%"
                        indeterminate
                        color="green"
                    ></v-progress-linear>
                </div>

                <div v-if="outdatedState == 'success'">
                    <v-icon size="50" style="color:green">mdi-check</v-icon>
                </div>
            </v-layout>

            <v-layout v-if="state == 'already-activated'" class="content-middler" column justify-center align-center>
                <v-icon size="100" style="color: red; border: 2px solid red; border-radius: 50%;"> mdi-exclamation </v-icon>
                <div style="width:90%; text-align: center; margin-top:30px; font-size: 20px; font-weight: 650;">{{ lview.error400accountActivate }}</div>
                <div class="mt-5">
                    <v-btn style="width: 200px;" color="primary" dark large @click="gotoLoginPage">
                        {{ lview.gotoLoginPage }}
                    </v-btn>
                </div>
            </v-layout>

            <v-layout v-if="state == 'error'" class="content-middler" column justify-center align-center>
                <v-icon size="100" style="color: red; border: 2px solid red; border-radius: 50%;"> mdi-exclamation </v-icon>
                <div style="width:90%; text-align: center; margin-top:30px; font-size: 20px; font-weight: 650;">{{ lview.error404message }}</div>
                <div class="mt-5">
                    <v-btn style="width: 200px;" color="primary" dark large @click="gotoLoginPage">
                        {{ lview.gotoLoginPage }}
                    </v-btn>
                </div>
            </v-layout>
        </v-container>

            <v-snackbar
                v-model="snackbar.visible"
                fixed
                class="snackbar-offline-notifier"
                :color="snackbar.color"
            >
                <v-flex xs12 class="align-self-center" style="font-size: 16px; text-align: center;">
                    <div>{{ snackbar.notification }}</div>
                </v-flex>
            </v-snackbar>

            <v-dialog persistent v-model="informationDialog.visible"
                max-width="295">
                <v-card>
                    <v-card-title style="font-size: 20px; font-weight: 550;">{{ informationDialog.title }}</v-card-title>

                    <v-card-text style="text-align:justify">{{ informationDialog.message }}</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="green darken-1" style="font-size: 12px;"
                            text
                            @click="informationDialog.visible = !informationDialog.visible">
                            {{ lbuttons.understood }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
    </div>
</template>

<script>
    // import jwt_decode from 'jwt-decode'
    //import axios from 'axios'
    const axios = require('axios');
    // import store from '../store.js'
    import appConfig from '@/app_config'

    export default {
        data() {
            return {
                loading: false,
                show1: false,
				rules: {
				required: value => !!value || 'Wymagane.',
				min: v => v.length >= 8 || 'Minimum 8 znaków',
				emailMatch: () => ('Email i hasło nie pasują')
                },
                password: "",
                repeatPassword: "",
                username: "",

                message1: '',
                isActiveSpinner: false,
                serviceAgree : false,
                politicsAgree : false,

                passwordShow: false,
                confirmPasswordShow: false,
                // ENVIRONMENT
                current_env: appConfig.getApiUrl(null),
                state: 'default',
                outdatedState: 'default', // "default" -> "sending" -> "success" (on success) | "default" (on error)
                // logo: fob_config.client_logo,
                showDatePicker: false,
                birthDate: "", // that one is being saved
                date: "",
                birthDateCorrect: true,
                errors: {
                    birthDate: {
                        trials: undefined,
                        label: "",
                    },
                    password: {
                        label: ""
                    },
                    confirmPassword: {
                        label: ""
                    }
                },
                politicsError: false,
                serviceError: false,

                validationRules: {
                    birthDate: value => {
                        return value.length > 0 && this.birthDateCorrect || this.lview.birthDateRequired;
                    },
                    password: value => {
                        if(value.length == 0){
                            return true;
                        }
                        let isOK = this.validatePassword(value);
                        return isOK || this.lview.invalidPasswordStructure;
                    },
                },

                informationDialog: {
                    visible: false,
                    title: "",
                    message: "",
                },

                snackbar: {
                    visible: false,
                    color: '',
                    notification: ''
                }
            }
        },
        computed: {
            lview: { get: function(){ return this.$t('views.accountActivation'); } },
            lbuttons: { get: function(){ return this.$t('commons.buttons'); } },
            lmessages: { get: function(){ return this.$t('commons.messages'); } },
        },
        mounted (){
            this.$emit("set-state", "default");
            // var that = this;
            axios({
                url: this.current_env + "/api/v1/activate-account/" + this.$route.params.token,
                method: "GET",
                headers: {
                    'Authorization': 'No Auth'
                }
            })
            .then(result => {
                this.state = 'default';
                this.username = result.data.username;
            })
            .catch(error => {
                console.log(error.response.data);
                if(error.response.status == 404){
                    this.$emit("set-state", "ERROR", this.lview.error404message);
                    this.state='error'
                }
                else if(error.response.status == 400){
                    if (error.response.data.token && error.response.data.token[0].code == "LINK_OUTDATED"){
                        this.state = 'outdated';
                        this.$emit("set-state", "DEFAULT");
                    }
                    else if(error.response.data.account && error.response.data.account[0].code == "ACCOUNT_ACTIVE"){
                        this.state = "already-activated";
                        this.$emit("set-state", "DEFAULT");
                        this.username = error.response.data.account[0].username;
                    }
                    else{
                        this.$emit('set-state', "ERROR", this.lview.error500message);
                    }
                }
                else if(error.response.status == 422){
                    this.$emit('set-state', "ERROR", this.lview.error500message);
                }
                else if(error.response.status == 429){
                    this.$emit('set-state', "ERROR", this.lview.error429message);
                }
                else if(error.response.status == 500){
                    this.$emit('set-state', "ERROR", this.lview.error500message);
                }
                else{
                    this.$emit("set-state", "ERROR");
                }
            });

            // let decoded = jwt_decode(this.$route.params.token);
            // if ((decoded.exp - (Date.now()/1000)) < 0){
            //     this.state = 'outdated';
            // } else {
            //     this.state = 'default';
            // }
        },
        beforeMount(){
            // this.$store.dispatch('logout');
        },
        methods:{
            gotoLoginPage(){
                this.$router.push('/login');
            },
            handleSubmit(){
                let passwordValid = this.validatePassword(this.password);
                this.politicsError = false;
                this.serviceError = false;
                this.errors.password.label = "";
                this.errors.confirmPassword.label = "";
                this.errors.birthDate.label = "";

                if(this.politicsAgree && this.serviceAgree && passwordValid && this.password == this.repeatPassword){
                    
                    this.$emit('set-state', 'loading_overlayer', this.lview.activatingAccount);

                    //place for checking whether user has logged in or not using API
                    //saving date of first logging into database
                    let formData = new FormData();
                    formData.append('password', this.password);
                    formData.append('password2', this.repeatPassword);
                    if (this.serviceAgree && this.politicsAgree){
                        formData.append('terms_and_conditions', true);
                    }
                    this.loading=true
                    var that=this
                    axios({
                        url: this.current_env + "/api/v1/activate-account/" + this.$route.params.token,
                        method: "POST",
                        data: formData,
                        headers: {
							'Authorization': 'No Auth'
						},
                    }).then(() => {
                        this.loading=false
                        this.snackbar.color="success"
                        this.snackbar.visible=true
                        this.snackbar.notification = this.lview.activationSuccess
                        setTimeout(function(){
                            that.$router.push("/login");
                        }, 3000);
                        this.$emit('set-state', 'default');
                    }).catch(error => {
                        console.log(error.response.data)
                        this.loading=false

                        this.$emit('set-state', 'default');
                        if (error.isAxiosError){
                            if(error.response == undefined || error.response == null){
                                this.snackbar.notification = "Application failed to connect to the server. Please check your internet connection.";
                                this.snackbar.visible = true;
                                this.snackbar.color = "error";
                                return;
                            }

                            this.informationDialog.visible = true;
                            this.informationDialog.title = this.lview.errorTitle;
                            
                            if (error.response.status == 400){
                                if (error.response.data.account != undefined){
                                    if(error.response.data.account[0].code=="ACCOUNT_ACTIVE"){
                                        this.informationDialog.message = this.lview.error400accountActivate;
                                    }
                                    else if(error.response.data.account[0].code=="ACCOUNT_BLOCKED"){
                                        this.informationDialog.message = this.lview.error400accountBlocked;
                                    }
                                }
                               else{
                                    this.informationDialog.message = this.lview.error400
                               }
                            }
                            else if(error.response.status == 404){
                                this.informationDialog.message = this.lview.error404message;
                            }
                            else if(error.response.status == 422){
                                this.informationDialog.message = this.lview.error500message;
                            }
                            else if(error.response.status == 500){
                                this.informationDialog.message = this.lview.error500message;
                            }
                        }
                        // handle other type of error
                    });
                }
                else{
                    if (!this.politicsAgree){
                        this.politicsError = true;
                    }
                    if (!this.serviceAgree){
                        this.serviceError = true;
                    }
                    if(!passwordValid){
                        this.errors.password.label = this.lview.invalidPasswordStructure;
                    }
                    if(this.password != this.repeatPassword){
                        this.errors.confirmPassword.label = this.lview.incorrectlyRepeatedPassword;
                    }
                    if(this.birthDate.length == 0){
                        this.errors.birthDate.label = this.lview.birthDateNotProvided;
                    }
                }
            },
            // activationCallback(responseData){
            //     if(responseData.result == "ok"){
            //         this.loginUser(responseData.username);
            //     } else if (responseData.result == "outdated"){
            //         this.state = 'outdated';
            //     }
            // },
            validatePassword(value){
                let specialSigns = "@$!%*?&#";

                let checks = {l: false, u: false, d: false, s: false};
                for(var i = 0; i < value.length; i++){
                    if (specialSigns.includes(value[i])){
                        checks.s = true;
                    } else if ('0' <= value[i] && value[i] <= '9'){
                        checks.d = true;
                    } else if ('a' <= value[i] && value[i] <= 'z'){
                        checks.l = true;
                    } else if ('A' <= value[i] && value[i] <= 'Z'){
                        checks.u = true;
                    }
                }
                let isOK = (value.length >= 8) && checks.u && (checks.d || checks.s) && checks.l; 
                return isOK;
            },
            validateRepeatPassword(){
                if (this.password != this.repeatPassword){
                    this.errors.confirmPassword.label = this.lview.incorrectlyRepeatedPassword;
                }
                else {
                    this.errors.confirmPassword.label = "";
                }
            },
            handlePoliticsAgreement(){
                this.politicsAgree = !this.politicsAgree;
            },
            handleServiceAgreement(){
                this.serviceAgree = !this.serviceAgree;
            },
            handleCancel(){
                this.$router.push('/')
            },
            resendActivationLink: async function(){
                var that=this
                this.outdatedState = "sending";
                return axios({
                    url: appConfig.getApiUrl(null) + "/api/v1/resend-activation-email/" + this.$route.params.token,
                    method: "POST"
                }).then(() => {
                    this.outdatedState = "success";
                    this.snackbar.color = 'success';
                    this.snackbar.notification = this.lview.newActivationLinkSent;
                    this.snackbar.visible = true;
                    setTimeout(function(){
                        that.$router.push("/login");
                    }, 3000);
                }).catch(error => {
                    this.outdatedState = "default";
                    if(error.isAxiosError){
                         if(error.response == undefined || error.response == null){
                            this.snackbar.notification = "Application failed to connect to the server. Please check your internet connection.";
                            this.snackbar.visible = true;
                            this.snackbar.color = "error";
                            return;
                        }
                        if(error.response.status == 404 || error.response.status == 403){
                            this.snackbar.color = 'error';
                            this.snackbar.notification = this.lview.couldNotSendNewEmail;
                            this.snackbar.visible = true;
                        }
                        else if(error.response.status == 429){
                            this.snackbar.color = 'error';
                            this.snackbar.notification = this.lview.error429message;
                            this.snackbar.visible = true;
                        }
                        else if(error.response.status == 500){
                            this.$emit('set-state', "ERROR");
                        }
                    } 
                });
            }
        }
    };
</script>

<style scoped>
    .login__top-logo{
        width: 90%;
        height: auto;
    }
    .login__top-logo-layout{
        width: 100%;
        height: 100px;
        position: relative;
    }

    .logging_writing{
        width: 100%;
        height: 40px; 
        color: green; 
        text-align: center;
        font-size: 18px;

        position: absolute;
    }

    .personal_data{
        width: 100%; 
        text-align: left;
        font-size: 18px;
        height : 30px;
    }

    .information{
        width: 100%;
        color: red;
    }

    .wrapper{
	width: 50%;
	margin-left: 25%;
	display: flex;
	justify-content: flex-end;
	align-content: center;
	flex-wrap: wrap;
}

    @media only screen and (max-width: 1904px) { 
	.wrapper {
		transform: translate(-120px,0);
	}
}

    @media only screen and (max-width: 1264px) { 
        .wrapper {
            transform: translate(-120px,0);
        }
    }

    @media only screen and (max-width: 960px) { 
        .wrapper {
            transform: translate(0);
        }
    }

    @media only screen and (max-width: 600px) { 
        .wrapper {
            transform: translate(0);
        }
}
</style>
